<template>
  <div>
    <b-card v-if="product">
      <b-tabs pills v-model="tabIndex">
        <b-tab v-for="tab of tabs" :key="tab.id" :title="tab.title"></b-tab>
        <template #tabs-end>
          <b-nav-item
            class="ms-auto"
            v-if="tabIndex === 2 && showVariantForm"
            href="#"
            role="presentation"
            @click="returned"
            >{{ $t("messages.back") }}</b-nav-item
          >
        </template>
      </b-tabs>
    </b-card>

    <product-form
      v-if="product"
      v-show="tabIndex === 0"
      :product-obj="product"
      :variants="variants"
      :action="action"
    />

    <product-files
      v-if="product"
      v-show="tabIndex === 1"
      :product-obj="product"
    />

    <product-variants-listing
      v-if="product"
      v-show="tabIndex === 2 && !showVariantForm"
      :product-obj="product"
      :reload="reload"
      @addVariant="proceedVariantForm"
      @variantSelected="proceedVariantForm"
    />

    <variant-form
      v-if="product"
      v-show="tabIndex === 2 && showVariantForm"
      :product-obj="product"
      :variant-iri="variant"
      @return="returned"
    />

    <product-custom-fields
      v-if="product"
      v-show="tabIndex === 3"
      :product-obj="product"
    />
  </div>
</template>

<script>
import ProductForm from "./components/ProductForm.vue";
import ProductFiles from "./components/ProductFiles.vue";
import ProductVariantsListing from "./components/ProductVariantsListing.vue";
import VariantForm from "./components/VariantForm.vue";
import ProductCustomFields from "./components/ProductCustomFields.vue";

export default {
  components: {
    ProductForm,
    ProductFiles,
    ProductVariantsListing,
    VariantForm,
    ProductCustomFields,
  },
  data() {
    return {
      action: "update",
      tabs: [
        {
          id: 0,
          title: this.$t("forms.general"),
        },
        {
          id: 1,
          title: this.$t("forms.imagesFiles"),
        },
        {
          id: 2,
          title: this.$t("variant.plural"),
        },
        {
          id: 3,
          title: this.$t("customField.plural"),
        },
      ],
      tabIndex: 0,
      product: null,
      variant: null,
      variants: null, // for default
      reload: 0,
      showVariantForm: false,
      perPage: this.$store.state.defaultPerPage,
      currentPage: 1,
    };
  },
  watch: {
    tabIndex() {
      this.showVariantForm = false;
    },
  },
  created() {
    this.loadProduct();
  },
  methods: {
    async loadProduct() {
      const id = this.$route.params.id;
      const productResponse = await this.$Products.getResource({ id });
      if (productResponse.status === 200) {
        const temp = productResponse.data;
        temp.status = {
          label: this.$helper.getEnumTranslation(
            "product_status",
            temp.status,
            this.$i18n.locale
          ),
          id: temp.status,
        };
        this.product = temp;
      }
    },
    proceedVariantForm(data) {
      if (data === "add") {
        this.variant = null;
        this.showVariantForm = true;
      } else {
        this.variant = data;
        this.showVariantForm = true;
      }
    },
    async returned() {
      this.showVariantForm = false;
      this.variant = null;
      this.reload++;
    },
  },
};
</script>
