<template>
  <b-card class="card-gray mt-4">
    <div v-if="documents && documents.length" class="documents custom-table">
      <table-lite
        v-if="documents.length"
        :is-slot-mode="true"
        :is-loading="isLoading"
        :columns="columns"
        :rows="documents"
        :sortable="{}"
        @is-finished="isLoading = false"
      >
        <template v-slot:name="data">
          <a :href="data.value.url" target="_blank">{{ data.value.label }}</a>
        </template>
        <template v-slot:actions="data">
          <div class="just-buttons">
            <delete-btn @pressDelete="deleteDocument(data.value.id)" />
          </div>
        </template>
      </table-lite>
      <table-pagination
        v-if="documents.length"
        v-model="currentPage"
        :total="pages"
        :per-page="perPage"
        @input="onChangePage"
      />
    </div>
    <document-upload @fileUploaded="addDocument" />
  </b-card>
</template>

<script>
import TableLite from "vue3-table-lite";
import TablePagination from "@/components/Table/TablePagination.vue";
import ResourceUtils from "@/mixins/ResourceUtils";
import DeleteBtn from "@/components/Buttons/DeleteBtn.vue";
import DocumentUpload from "@/components/DocumentUpload.vue";

export default {
  components: {
    TableLite,
    TablePagination,
    DeleteBtn,
    DocumentUpload,
  },
  props: {
    modelValue: { type: [Array], required: true },
  },
  mixins: [ResourceUtils],
  data() {
    return {
      files: this.modelValue,
      isLoading: false,
      perPage: this.$store.state.defaultPerPage,
      currentPage: 1,
      pages: 0,
      columns: [
        {
          label: this.$t("forms.name"),
          field: "name",
          sortable: false,
        },
        {
          label: this.$t("forms.actions"),
          field: "actions",
          sortable: false,
          width: "120px",
        },
      ],
    };
  },
  watch: {
    modelValue: {
      immediate: true,
      handler(val) {
       
        this.files = val;
        
      },
    },
  },
  computed: {
    documents() {
      let documents = [];
      this.files.forEach((element) => {
        if (!element.mimeType.includes("image")) {
          documents.push(element);
        }
      });
      return documents;
    },
  },
  methods: {
    deleteDocument(id) {
      this.delete(this.$Files, id, this.$t("file.deleted"));
      this.files.forEach(element => {
          if(element.id === id) {
              const index = this.files.indexOf(element)
              this.files.splice(index, 1)
          }
      });
    },
    addDocument(data) {
      this.files.push(data);
      this.$emit("update:modelValue", this.files);
    },
  },
};
</script>

<style>
</style>