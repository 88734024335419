<template>
  <div v-if="variants" class="custom-table">
    <table-actions
      :actions="['addBtnEmit', 'perPage']"
      :addBtnText="$t('variant.add')"
      @addBtnClicked="$emit('addVariant', 'add')"
      @onSearchInput="inputChange"
      @perPageChange="perPageChange"
      :defaultPerPage="perPage"
    />

    <table-lite
      :is-slot-mode="true"
      :is-loading="isLoading"
      :columns="columns"
      :rows="variants"
      :sortable="{}"
      @is-finished="isLoading = false"
    >
      <template v-slot:name="data">
        <strong @click="$emit('variantSelected', data.value['@id'])">{{ data.value.name }} (ID: {{ data.value.id }})</strong>
      </template>

      <template v-slot:parameters="data">
        {{ $helper.buildParamString(data.value.customParameters) }}
      </template>

      <template v-slot:actions="data">
        <div class="just-buttons">
          <span class="custom-btn">
            <bootstrap-icon
              @click="$emit('variantSelected', data.value['@id'])"
              icon="pencil"
              size="1x"
            />
          </span>
          <delete-btn @pressDelete="deleteVariant(data.value['@id'])" />
        </div>
      </template>
    </table-lite>

    <table-pagination
      v-if="pages"
      v-model="currentPage"
      :total="pages"
      :per-page="perPage"
      @input="onChangePage"
    />
  </div>
</template>

<script>
import TableLite from "vue3-table-lite";
import TablePagination from "@/components/Table/TablePagination.vue";
import DeleteBtn from "@/components/Buttons/DeleteBtn.vue";
import TableActions from "@/components/Table/TableActions.vue";
import ListUtils from "@/mixins/ListUtils";
import ResourceUtils from "@/mixins/ResourceUtils";

export default {
  components: {
    TableActions,
    TableLite,
    TablePagination,
    DeleteBtn,
  },
  props: {
    productObj: { type: Object, default() {} },
    reload: { type: Number, default: 0 }
  },
  data() {
    return {
      product: JSON.parse(JSON.stringify(this.productObj)),
      isLoading: false,
      variants: null,
      perPage: this.$store.state.defaultPerPage,
      currentPage: 1,
      pages: 0,
      columns: [
        {
          label: this.$t("variant.name"),
          field: "name",
          sortable: false,
        },
        {
          label: this.$t("variant.parameters"),
          field: "parameters",
          sortable: false,
        },
        {
          label: this.$t("variant.actions"),
          field: "actions",
          sortable: false,
          width: "120px",
        },
      ],
    };
  },
  mixins: [ListUtils, ResourceUtils],
  created() {
    this.loadProductVariants()
  },
  watch: {
    reload() {
      // this.product = JSON.parse(JSON.stringify(this.productObj))
      // this.variants = this.product.variants.slice(0, this.perPage);
      // this.pages = this.product.variants.length;
      this.loadProductVariants()
    }
  },
  methods: {
    loadProductVariants() {
      const params = {
        itemsPerPage: this.perPage,
        page: this.currentPage,
        "order[createdAt]": "desc",
      };
      this.$Products
        .getResourceByUrl({ url: `${this.product["@id"]}/variants`, params })
        .then((response) => {
          this.pages = response.data["hydra:totalItems"]
          this.variants = response.data["hydra:member"]
        });
    },
    onChangePage(page) {
      this.currentPage = page;
      this.loadProductVariants();
    },
    deleteVariant(url) {
      this.deleteByUrl(
        this.$ProductVariants,
        url,
        this.$t("variant.deleted"),
        null,
        this.loadProductVariants
      );
    },
    perPageChange(num) {
      this.currentPage = 1;
      this.perPage = num;
      this.loadProductVariants();
    },
    onPageChange() {
      this.loadProductVariants();
    }
  },
};
</script>
