<template>
  <b-card>
    <custom-fields ownerResource="PRODUCT" :ownerId="product.id" :existingCustomFields="customFields" />
  </b-card>
</template>

<script>
import CustomFields from "@/components/CustomFields/customFields.vue";

export default {
  props: {
    productObj: { type: Object, default() {} },
  },
  components: {
    CustomFields,
  },
  data() {
    return {
      product: JSON.parse(JSON.stringify(this.productObj)),
      customFields: this.productObj.customFields
    };
  },
};
</script>
