<template>
  <div class="cfb" v-if="loaded">
    <h5>{{ $t("customField.plural") }}</h5>
    <div class="cfb-add">
      <b-form-group :label="$t('customField.type')">
        <v-select
          :clearable="false"
          :options="customFieldTypes"
          v-model="selected"
        />
      </b-form-group>

      <b-form-group :label="$t('customField.name')">
        <b-form-input v-model="selectedName"/>
      </b-form-group>

      <b-form-group>
        <b-button
          @click="addCustomField"
          :disabled="!selected || !selectedName.length"
          variant="secondary"
        >{{ $t("customField.add") }}
        </b-button
        >
      </b-form-group>
    </div>
    <div class="cfb-fields" v-if="customFields && customFields.length">
      <table>
        <thead>
        <tr>
          <th>{{ $t("customField.type") }}</th>
          <th>{{ $t("customField.name") }}</th>
          <th>{{ $t("customField.value") }}</th>
          <th></th>
        </tr>
        </thead>
        <tr class="cfb-field" v-for="(cf, index) of customFields" :key="index">
          <td class="cfb-field-type">
            {{
              $helper.getEnumTranslation(
                "custom_field_type",
                cf.type,
                this.$i18n.locale
              )
            }}
          </td>
          <td class="cfb-field-name">
            <b-form-group>
              <b-form-input @blur="slugifyCF(cf)" v-model="cf.name"/>
            </b-form-group>
            <image-upload
              v-if="cf.type === 'FILE_IRI'"
              :preview-image="cf.fileUrl"
              label=""
              :parent-object="cf"
              @fileUploaded="ilustrationImageChange"
            />
          </td>
          <td class="cfb-field-value">
            <b-form-input v-if="cf.type === 'STRING'" v-model="cf.value"/>
            <b-form-textarea v-if="cf.type === 'TEXT'" v-model="cf.value"/>

            <b-form-input
              v-if="cf.type === 'NUMBER'"
              type="number"
              v-model="cf.value"
            />

            <b-form-checkbox
              v-if="cf.type === 'BOOLEAN'"
              value="true"
              unchecked-value="false"
              v-model="cf.value"
              switch
              size="lg"
            ></b-form-checkbox>

            <tinymce
              v-if="cf.type === 'WYSIWYG'"
              :content="cf.value"
              :parent-object="cf"
              @editorContentChanged="updateContent"
            />

            <datepicker
              v-if="cf.type === 'DATE'"
              v-model="cf.value"
              :enableTimePicker="false"
              class="form-input"
              format="dd. MM. yyyy"
              previewFormat="dd. MM. yyyy"
            ></datepicker>

            <v-select
              v-if="cf.type === 'ARTICLE_IRI'"
              :options="articles"
              v-model="cf.value"
            />

            <v-select
              v-if="cf.type === 'WEB_PAGE_IRI'"
              :options="pages"
              v-model="cf.value"
            />

            <v-select
              v-if="cf.type === 'PRODUCT_IRI'"
              :options="products"
              v-model="cf.value"
            />

            <v-select
              v-if="cf.type === 'PRODUCT_VARIANT_IRI'"
              :options="variants"
              v-model="cf.value"
            />

            <v-select
              v-if="cf.type === 'USER_IRI'"
              :options="users"
              v-model="cf.value"
            />

            <v-select
              v-if="cf.type === 'CATEGORY_IRI'"
              :options="categories"
              v-model="cf.value"
            />

            <v-select
              v-if="cf.type === 'CUSTOM_RESOURCE_IRI'"
              :options="variables"
              v-model="cf.value"
            />

            <v-select
              v-if="cf.type === 'REVIEW_IRI'"
              :options="reviews"
              v-model="cf.value"
            />

            <div v-if="cf.type === 'JSON_STRING'">
              <div
                class="cfb-json card"
                v-for="(el, ix) of cf.value"
                :key="ix"
              >
                <div class="cfb-json-row">
                  <div class="cfb-json-inputs">
                    <b-form-input class="mb-3" v-model="el.key"/>
                    <tinymce
                      :content="el.value"
                      :parent-object="el"
                      @editorContentChanged="updateContent"
                    />
                  </div>
                  <div class="cfb-json-remove">
                    <b-button
                      @click="removeFromJsonString(cf, ix)"
                      variant="secondary"
                    >
                      <bootstrap-icon icon="trash" size="1x"/>
                    </b-button>
                  </div>
                </div>
              </div>
              <b-button @click="addToJsonString(cf)" variant="secondary">{{
                  $t("forms.add")
                }}
              </b-button>
            </div>
          </td>
          <td class="cfb-field-remove">
            <b-button variant="secondary" @click="removeCustomField(cf, index)">
              <bootstrap-icon icon="trash" size="1x"/>
            </b-button>
          </td>
        </tr>
      </table>
    </div>
    <b-button
      v-if="customFields && customFields.length"
      @click="saveCustomFields"
      variant="primary"
    >
      {{ $t("customField.save") }}
    </b-button>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import moment from "moment";
import Toast from "@/components/Toast.vue";
import {useToast} from "vue-toastification";
import ImageUpload from "@/components/ImageUpload.vue";
import vSelect from "vue-select";
import tinymce from "@/components/tinymce.vue";
import ListUtils from "@/mixins/ListUtils";
import ResourceUtils from "@/mixins/ResourceUtils";
import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";

export default {
  components: {
    vSelect,
    tinymce,
    Datepicker,
    ImageUpload,
  },
  props: {
    ownerResource: {type: String, required: true},
    ownerId: {type: Number, default: null},
    existingCustomFields: {type: Array, default: () => []},
  },
  mixins: [ListUtils, ResourceUtils],
  data() {
    return {
      customFieldTypes: [],
      selected: {},
      selectedName: "",
      customFields: JSON.parse(JSON.stringify(this.existingCustomFields)),
      articles: [],
      pages: [],
      products: [],
      variants: [],
      users: [],
      categories: [],
      variables: [],
      reviews: [],
      loaded: false
    };
  },
  watch: {
    existingCustomFields: {
      deep: true,
      async handler(n, o) {
        if (JSON.stringify(o) != JSON.stringify(n)) {
          this.customFields = n;
        }
      },
    },
  },
  async created() {
    await this.getEnumList("custom_field_type", this.customFieldTypes);
    this.customFieldTypes = this.customFieldTypes.filter(
      (cft) => !["DATETIME", "URL", "IMAGE_URL"].includes(cft.id)
    );
    this.selected = this.customFieldTypes[0];
    this.load(this.customFields);
  },
  methods: {
    async loadSelect(forceLoad) {
      if (forceLoad === "ARTICLE_IRI" || this.selected.id === "ARTICLE_IRI") {
        return this.articles.length
          ? this.articles
          : await this.getArticleList(this.articles);
      }

      if (forceLoad === "WEB_PAGE_IRI" || this.selected.id === "WEB_PAGE_IRI") {
        return this.pages.length
          ? this.pages
          : await this.getPageList(this.pages);
      }

      if (forceLoad === "PRODUCT_IRI" || this.selected.id === "PRODUCT_IRI") {
        return this.products.length
          ? this.products
          : await this.getProductsList(this.products);
      }

      if (
        forceLoad === "PRODUCT_VARIANT_IRI" ||
        this.selected.id === "PRODUCT_VARIANT_IRI"
      ) {
        return this.variants.length
          ? this.variants
          : await this.getProductVariantsList(this.variants);
      }

      if (forceLoad === "USER_IRI" || this.selected.id === "USER_IRI") {
        return this.users.length
          ? this.users
          : await this.getUserList({params: {}}, this.users);
      }

      if (forceLoad === "CATEGORY_IRI" || this.selected.id === "CATEGORY_IRI") {
        return this.categories.length
          ? this.categories
          : await this.getCategoriesList(this.categories);
      }

      if (
        forceLoad === "CUSTOM_RESOURCE_IRI" ||
        this.selected.id === "CUSTOM_RESOURCE_IRI"
      ) {
        return this.variables.length
          ? this.variables
          : await this.getVariablesList(this.variables);
      }

      if (forceLoad === "REVIEW_IRI" || this.selected.id === "REVIEW_IRI") {
        return this.reviews.length
          ? this.reviews
          : await this.getReviewsList(this.reviews);
      }
    },
    async load(cfArray) {
      this.loaded = false
      for (const cf of cfArray) {
        if (cf.type === "DATE") {
          cf.value = new Date(cf.value);
        }

        if (
          [
            "ARTICLE_IRI",
            "WEB_PAGE_IRI",
            "PRODUCT_IRI",
            "PRODUCT_VARIANT_IRI",
            "USER_IRI",
            "CATEGORY_IRI",
            "CUSTOM_RESOURCE_IRI",
            "REVIEW_IRI",
          ].includes(cf.type)
        ) {
          if (cf.value && cf.value["@id"]) {
            cf.value = cf.value["@id"]; // kvoli embedovaniu
          }
          let list = await this.loadSelect(cf.type);
          if (list) {
            if (cf.type === "USER_IRI") {
              list = list[0];
            }
            const exists = list.find((e) => e.id === cf.value);
            if (exists) {
              cf.value = exists;
            }
          }
        }

        if (cf.type === "FILE_IRI") {
          if (cf.value && cf.value["@id"]) {
            cf.value = cf.value["@id"]; // kvoli embedovaniu
            const file = await this.$Files.getResourceByUrl({url: cf.value});
            if (file.status === 200) {
              cf.file = file.data["@id"];
              cf.fileUrl = file.data.url;
            }
          }
        }

        if (cf.type === "JSON_STRING") {
          cf.value = JSON.parse(cf.value)
        }
      }
      this.loaded = true
    },
    ilustrationImageChange({parent, data}) {
      if ("file" in parent) {
        this.deleteByUrl(this.$Files, parent.file, null);
      }
      parent.file = data["@id"];
      parent.fileURL = data.url;

      if ("id" in parent) {
        const body = {
          file: parent.file,
        };
        this.update(this.$CustomFields, parent.id, body);
      }
    },
    async addCustomField() {
      await this.loadSelect();
      const cf = {
        type: this.selected.id,
        value: "",
        ownerResource: this.ownerResource,
        name: this.slugify(this.selectedName),
      };

      if (this.ownerId) {
        cf.ownerId = this.ownerId;
      }

      if (cf.type === "BOOLEAN") {
        cf.value = false;
      }

      if (
        cf.type === "ARTICLE_IRI" ||
        cf.type === "PRODUCT_IRI" ||
        cf.type === "WEB_PAGE_IRI" ||
        cf.type === "PRODUCT_VARIANT_IRI" ||
        cf.type === "USER_IRI" ||
        cf.type === "CATEGORY_IRI" ||
        cf.type === "CUSTOM_RESOURCE_IRI" ||
        cf.type === "REVIEW_IRI"
      ) {
        cf.value = {};
      }

      if (cf.type === "FILE_IRI") {
        cf.value = {
          fileUrl: "",
          file: "",
        };
      }

      if (cf.type === "JSON_STRING") {
        cf.value = [
          {
            key: "",
            value: "",
          },
        ];
      }

      this.customFields.push(cf);
    },
    removeCustomField(cf, index) {
      if (cf["@id"]) {
        this.deleteByUrl(this.$CustomFields, cf["@id"], null, null, () => {
          this.customFields.splice(index, 1);
        });
      } else {
        this.customFields.splice(index, 1);
      }
    },
    slugify(text) {
      return this.$helper.slugify(text);
    },
    slugifyCF(cf) {
      cf.name = this.$helper.slugify(cf.name);
    },
    updateContent(data) {
      data.parent.value = data.content;
    },
    addToJsonString(cf) {
      cf.value.push({
        key: "",
        value: "",
      });
    },
    removeFromJsonString(cf, index) {
      cf.value.splice(index, 1);
    },
    saveCustomFields() {
      const promises = [];
      for (const cf of this.customFields) {
        const body = JSON.parse(JSON.stringify(cf));
        body.ownerResource = this.ownerResource;
        if (this.ownerId) {
          body.ownerId = this.ownerId;
        }
        if (
          [
            "ARTICLE_IRI",
            "WEB_PAGE_IRI",
            "PRODUCT_IRI",
            "PRODUCT_VARIANT_IRI",
            "USER_IRI",
            "CATEGORY_IRI",
            "CUSTOM_RESOURCE_IRI",
            "REVIEW_IRI",
          ].includes(body.type)
        ) {
          body.value = body.value ? body.value.id : "";
        }

        if (cf.type === "FILE_IRI") {
          body.value = body.file ? body.file : "";
        }

        if (cf.type === "DATE") {
          body.value = body.value ? moment(body.file).format() : "";
        }

        if (cf.type === "JSON_STRING") {
          body.value = body.value ? JSON.stringify(body.value) : "";
        }

        if (body["@id"]) {
          promises.push(this.update(this.$CustomFields, body.id, body));
        } else {
          promises.push(this.create(this.$CustomFields, body));
        }
      }
      Promise.all(promises).then((results) => {
        if (results.length) {
          this.$CustomFields
            .getCollection({
              params: {
                ownerId: this.ownerId,
                ownerResource: this.ownerResource,
              },
            })
            .then((response) => {
              this.customFields = response.data["hydra:member"];
              this.load(this.customFields)
              this.$helper.showToast(
                useToast(),
                Toast,
                this.$t("customField.saved"),
                null,
                "success"
              );
            });
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "vue-select/src/scss/vue-select.scss";
</style>
