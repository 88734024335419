<template>
  <b-card class="card-gray mt-4">
    <h5 class="mb-4" v-if="galleryTitle">{{ galleryTitle }}</h5>
    <div class="gallery">
      <draggable
        v-if="imageFiles && imageFiles.length"
        :list="imageFiles"
        item-key="id"
        class="gallery-image"
        @change="orderChanged"
      >
        <template #item="{ element }">
          <div class="gallery-image-item">
            <image-upload
              :for-banner="true"
              :preview-image="element.url"
              :resource="this.resource"
              :fileAttribute="this.fileAttribute"
              :label="element.url ? '' : $t('file.imageAdd')"
              :multiple="!single"
              @fileUploaded="ilustrationImageChange"
            />
            <div
              class="trash"
              v-if="element.url"
              @click="removeImage(element.id)"
            >
              <bootstrap-icon icon="trash" size="1x"/>
            </div>
          </div>
        </template>
      </draggable>
    </div>

    <b-button
      v-if="!(single && imageFiles && imageFiles.length >= 1) || !single"
      variant="primary"
      :disabled="!(enableAdding || imageFiles.length === 0)"
      @click="addImage"
    >
      <bootstrap-icon icon="plus"/>
      {{ $t("file.imageAdd") }}
    </b-button>
  </b-card>
</template>

<script>
import ImageUpload from "@/components/ImageUpload.vue";
import draggable from "vuedraggable";
import ResourceUtils from "@/mixins/ResourceUtils";

export default {
  components: {
    ImageUpload,
    draggable,
  },
  props: {
    files: {type: [Array, Object], required: true},
    single: {type: Boolean, default: false},
    galleryTitle: {type: String, default: ""},
    resource: {type: String, default: null},
    fileAttribute: {type: String, default: null},
  },
  mixins: [ResourceUtils],
  data() {
    return {
      enableAdding: true,
      imageFiles: []
    };
  },
  watch: {
    files: {
      immediate: true,
      handler(val) {
        if (this.single) {
          if (val) {
            this.imageFiles = [val];
          } else {
            this.imageFiles = [];
          }
        } else {
          this.imageFiles = val;
        }
      }
    }
  },
  created() {
    if (this.single) {
      if (this.files) {
        this.imageFiles.push(this.files)
      }
    } else {
      this.imageFiles = this.files
    }
  },
  methods: {
    ilustrationImageChange(data) {
      if (Array.isArray(data)) {
        this.imageFiles.pop();
        data.forEach((element) => {
          this.imageFiles.push(element);
        });
      } else {
        this.imageFiles[this.imageFiles.length - 1].id = data.id;
        this.imageFiles[this.imageFiles.length - 1]["@id"] = data["@id"];
        this.imageFiles[this.imageFiles.length - 1].url = data.url;
        this.imageFiles[this.imageFiles.length - 1].mimeType = data.mimeType;
      }
      this.enableAdding = true;
      if (this.single) {
        this.$emit("updated", this.imageFiles[0]);
      } else {
        this.$emit("updated", this.imageFiles);
      }
    },
    orderChanged() {
      this.$emit("updated", this.imageFiles);
    },
    addImage() {
      const obj = {};
      obj["@id"] = -1;
      obj.url = null;
      obj.mimeType = "image";
      this.imageFiles.push(obj);
      this.enableAdding = false;
    },
    removeImage(id) {
      this.delete(this.$Files, id, this.$t("file.deleted"));
      this.imageFiles = this.imageFiles
        .map((item) => ({...item}))
        .filter((item) => item.id !== id);
      if (this.single) {
        this.$emit("updated", null);
      } else {
        this.$emit("updated", this.imageFiles);
      }
    },
  },
};
</script>
