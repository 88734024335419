<template>
  <b-form v-if="product" class="product-form">
    <gallery
      :files="file"
      :single="true"
      :galleryTitle="$t('product.illustrationImage')"
      v-if="product['@id']"
      :resource="product['@id']"
      file-attribute="FILE"
      @updated="updateFile"
    />

    <gallery
      :files="files"
      :galleryTitle="$t('product.gallery')"
      v-if="product['@id']"
      :resource="product['@id']"
      file-attribute="FILES"
      @updated="updateFiles"
    />

    <document-list
      class="mb-4"
      v-model="files"
      v-on:update:modelValue="updateFiles"
    />
  </b-form>
</template>

<script>
import Gallery from "./Gallery.vue";
import ListUtils from "@/mixins/ListUtils";
import ResourceUtils from "@/mixins/ResourceUtils";
import DocumentList from "./DocumentList.vue";

export default {
  props: {
    productObj: {
      type: Object, default() {
      }
    },
  },
  components: {
    // eslint-disable-next-line vue/no-unused-components
    Gallery,
    DocumentList,
  },
  data() {
    return {
      product: JSON.parse(JSON.stringify(this.productObj)),
      files: [],
      file: null,
    };
  },
  mixins: [ListUtils, ResourceUtils],
  created() {
    if (this.product.id) {
      this.$Products
        .getResourceFiles({id: this.product.id})
        .then((response) => {
          if (response.status === 200) {
            this.files = response.data["hydra:member"].map(f => {
              f.index = 0
              return f
            });
            if(this.product.filesOrder){
              this.product.filesOrder.forEach((fo, ix) => {
                const file = this.files.find(f => f['@id'] === fo)
                if (file) {
                  file.index = ix
                }
              })
              this.files.sort((a, b) => parseFloat(a.index) - parseFloat(b.index));
            }
          }
        });
    }

    if (this.product.file) {
      this.$Files
        .getResourceByUrl({url: this.product.file})
        .then((response) => {
          if (response.status === 200) {
            this.file = response.data;
          }
        });
    }
  },
  methods: {
    updateFiles(data) {
      const filesOrder = data.map(file => file['@id'])
      this.update(this.$Products, this.product.id, { filesOrder }, this.$t("product.updated"));
    }
  }
};
</script>
