
<template>
  <b-form v-if="product" class="product-form" @submit.prevent="onSubmit">
    <b-container class="full-w">
      <b-row>
        <b-col cols="12" lg="8">
          <b-card>
            <h5 class="mb-2">{{ $t("product.info") }}</h5>
            <b-form-group
              :label="$t('product.name')"
              :class="{ row, error: v$.product.name.$errors.length }"
            >
              <b-form-input @blur="presetSlug" v-model="product.name" />
              <div
                class="input-errors"
                v-for="error of v$.product.name.$errors"
                :key="error.$uid"
              >
                <small class="text-danger">{{ error.$message }}</small>
              </div>
            </b-form-group>

            <b-form-group>
              <label class="label-sm mb-1">{{
                $t("product.description")
              }}</label>
              <tinymce
                :content="product.description"
                @editorContentChanged="updateDescription"
              />
            </b-form-group>
          </b-card>
        </b-col>

        <b-col cols="12" lg="4">
          <b-card>
            <h5 class="mb-2">{{ $t("product.properties") }}</h5>
            <b-form-group
              :label="$t('product.status')"
              :class="{ row, error: v$.product.status.$errors.length }"
            >
              <v-select v-model="product.status" :options="statuses" />
              <div
                class="input-errors"
                v-for="error of v$.product.status.$errors"
                :key="error.$uid"
              >
                <small class="text-danger">{{ error.$message }}</small>
              </div>
            </b-form-group>

            <b-form-group :label="$t('product.publishDate')">
              <datepicker
                v-model="product.date"
                format="dd. MM. yyyy | HH:mm"
                previewFormat="dd. MM. yyyy | HH:mm"
              ></datepicker>
            </b-form-group>

            <b-form-group
              :label="$t('product.categories')"
              :class="{ row, error: v$.product.categories.$errors.length }"
            >
              <v-select
                multiple
                v-model="product.categories"
                :options="availableCategories"
              />
              <div
                class="input-errors"
                v-for="error of v$.product.categories.$errors"
                :key="error.$uid"
              >
                <small class="text-danger">{{ error.$message }}</small>
              </div>
            </b-form-group>

            <b-form-group
              :label="$t('product.category')"
              :class="{ row, error: v$.product.category.$errors.length }"
            >
              <v-select
                v-model="product.category"
                :options="product.categories"
              />
              <div
                class="input-errors"
                v-for="error of v$.product.category.$errors"
                :key="error.$uid"
              >
                <small class="text-danger">{{ error.$message }}</small>
              </div>
            </b-form-group>

            <b-form-group
              v-if="availableVariants && availableVariants.length"
              :label="$t('product.defaultVariant')"
            >
              <v-select
                v-model="product.defaultVariant"
                :options="availableVariants"
              />
            </b-form-group>

            <b-form-group :label="$t('product.location')">
              <v-select
                v-model="product.location"
                :options="availableLocations"
              />
            </b-form-group>

            <h5 class="mb-2">{{ $t("product.seo") }}</h5>
            <b-form-group
              :label="$t('product.slug')"
              :class="{ error: v$.product.url.$errors.length }"
            >
              <b-form-input @blur="slugifyURL" v-model="product.url" />
              <div
                class="input-errors"
                v-for="error of v$.product.url.$errors"
                :key="error.$uid"
              >
                <small class="text-danger">{{ error.$message }}</small>
              </div>
            </b-form-group>

            <b-form-group :label="$t('product.metaTitile')">
              <b-form-input v-model="product.keywords" />
            </b-form-group>

            <b-form-group :label="$t('product.metaDescription')">
              <b-form-textarea v-model="product.metaDescription" />
            </b-form-group>

            <b-form-group :label="$t('product.excerpt')">
              <b-form-textarea v-model="product.excerpt" />
            </b-form-group>

            <b-form-group :label="$t('forms.tags')">
              <v-select
                taggable
                multiple
                push-tags
                v-model="product.tags"
                :options="product.tags"
              />
            </b-form-group>

            <b-button variant="primary" @click="onSubmit">
              <bootstrap-icon v-if="!product.id" icon="plus" />
              {{ !product.id ? $t("product.add") : $t("product.save") }}
            </b-button>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </b-form>
</template>

<script>
import moment from "moment";
import tinymce from "@/components/tinymce.vue";
import vSelect from "vue-select";
import useVuelidate from "@vuelidate/core";
import ListUtils from "@/mixins/ListUtils";
import ResourceUtils from "@/mixins/ResourceUtils";
import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";

export default {
  props: {
    productObj: { type: Object, default() {} },
    variants: { type: Array, default: () => [] },
    action: { type: String, default: null },
  },
  components: {
    vSelect,
    tinymce,
    Datepicker,
  },
  data() {
    return {
      product: JSON.parse(JSON.stringify(this.productObj)),
      statuses: [],
      originalStatuses: [],
      availableCategories: [],
      availableLocations: [],
      availableVariants: [],
      date: null,
    };
  },
  mixins: [ListUtils, ResourceUtils],
  async created() {
    await this.getEnumList("product_status", this.originalStatuses);
    await this.getCategoriesList(this.availableCategories);
    await this.getLocationList(this.availableLocations);
    if (this.product["@id"]) {
      const variantResponse = await this.$Products.getResourceByUrl(
        {
          url: `${this.product["@id"]}/variants`,
          params: { pagination: false },
        },
        "id_name"
      );
      if (variantResponse.status === 200) {
        for (const variant of variantResponse.data["hydra:member"]) {
          this.availableVariants.push({
            label: `${variant.name} (ID:${variant.id})`,
            id: variant["@id"],
          });
        }
      }
    }

    if (this.product.defaultVariant) {
      const existing = this.availableVariants.find(
        (variant) => variant.id === this.product.defaultVariant["@id"]
      );
      this.product.defaultVariant = existing;
    }

    if (this.product.category) {
      const existing = this.availableCategories.find(
        (category) => category.id === this.product.category["@id"]
      );
      this.product.category = existing;
    }
    if (this.product.categories && this.product.categories.length) {
      const temp = [];
      for (const cat of this.product.categories) {
        const existing = this.availableCategories.find(
          (category) => category.id === cat
        );
        temp.push(existing);
      }
      this.product.categories = temp;
    }
    if (this.product.location) {
      const existing = this.availableLocations.find(
        (location) => location.id === this.product.location["@id"]
      );
      this.product.location = existing;
    }
    // produkt bez variant sa neda publikovat
    this.filterStatuses()
  },
  setup: () => ({ v$: useVuelidate() }),
  validations() {
    return {
      product: {
        name: { required: this.$translateError("required") },
        url: { required: this.$translateError("required") },
        status: { required: this.$translateError("required") },
        category: { required: this.$translateError("required") },
        categories: { required: this.$translateError("required") },
      },
    };
  },
  watch: {
    product: {
      deep: true,
      handler() {
        if (
          this.product.categories &&
          this.product.categories.length &&
          this.product.category
        ) {
          const exist = this.product.categories.find(
            (c) => c.id === this.product.category.id
          );
          if (!exist) {
            this.product.category = null;
          }
        }
        if (this.product.categories && !this.product.categories.length) {
          this.product.category = null;
        }
      },
    },
  },
  methods: {
    async onSubmit() {
      const isValid = await this.v$.$validate();
      if (isValid) {
        const body = {
          name: this.product.name,
          description: this.product.description,
          date: moment(this.product.date).format(),
          url: this.product.url,
          excerpt: this.product.excerpt,
          keywords: this.product.keywords,
          metaDescription: this.product.metaDescription,
          tags: this.product.tags,
        };

        body.status = this.product.status.id;
        if (this.product.category) {
          body.category = this.product.category.id;
        }
        if (this.product.categories && this.product.categories.length) {
          body.categories = this.product.categories.map((c) => c.id);
        }
        if (this.product.location) {
          body.location = this.product.location.id;
        } else {
          body.location = null
        }
        if (this.product.defaultVariant) {
          body.defaultVariant = this.product.defaultVariant.id;
        } else {
          body.defaultVariant = null
          if (body.status === 'ACTIVE') {
            body.status = 'DRAFT'
          }
        }

        if (this.action) {
          this.action === "add"
            ? this.create(
                this.$Products,
                body,
                this.$t("product.created"),
                null,
                this.success,
                this.error
              )
            : this.update(
                this.$Products,
                this.product.id,
                body,
                this.$t("product.updated"),
                null,
                this.success(),
                this.error
              );
          this.$emit("clearAction");
        }
      } else {
        this.$emit("clearAction");
      }
    },
    updateDescription(content) {
      this.product.description = content;
    },
    presetSlug() {
      if (this.product.url.length === 0) {
        this.product.url = this.$helper.slugifyURL(this.product.name);
      }
    },
    slugifyURL() {
      if (this.product.url.length !== 0) {
        this.product.url = this.$helper.slugifyURL(this.product.url);
      }
    },
    filterStatuses() {
      this.statuses = this.originalStatuses.filter((status) => {
        if (!this.product.id || !this.product.defaultVariant) {
          return status.id !== "ACTIVE";
        }
        return true;
      });
    },
    success() {
      this.$router.push(`/products`);
    },
    error() {
      this.$emit("clearAction");
    },
  },
};
</script>

<style lang="scss">
@import "vue-select/src/scss/vue-select.scss";
</style>
