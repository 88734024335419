<template>
  <b-form v-if="product" class="product-form" @submit.prevent="onSubmit">
    <b-card>
      <div class="row">
        <div class="col-4">
          <h5 class="mb-2">{{ $t("product.variantParams") }}</h5>
          <v-select
            multiple
            v-model="variantParams"
            :options="availableVariantParams"
          />
        </div>

        <div class="col-4">
          <h5 class="mb-2">{{ $t("product.infoParams") }}</h5>
          <v-select
            multiple
            v-model="infoParams"
            :options="availableInfoParams"
          />
        </div>

        <div
          v-if="variant"
          class="col-4 d-flex align-items-end justify-content-end"
        >
          <b-button variant="primary" type="submit">{{
            $t("variant.save")
          }}</b-button>
        </div>
      </div>
    </b-card>

    <b-container v-if="variant" class="full-w">
      <b-row>
        <b-col cols="12" lg="8">
          <b-card>
            <h5 class="mb-2">
              {{ $t("variant.baseInfo") }}
              {{ variant.id ? ` (ID: ${variant.id})` : "" }}
            </h5>
            <b-form-group
              :class="{ error: v$.variant.name.$errors.length }"
              :label="$t('variant.name')"
            >
              <b-form-input
                @blur="presetSlug(variant, 'name', 'url')"
                v-model="variant.name"
              />
              <div
                class="input-errors"
                v-for="error of v$.variant.name.$errors"
                :key="error.$uid"
              >
                <small class="text-danger">{{ error.$message }}</small>
              </div>
            </b-form-group>

            <b-form-group
              :class="{ error: v$.variant.url.$errors.length }"
              :label="$t('variant.url')"
            >
              <b-form-input
                @blur="slugifyURL(variant, 'url')"
                v-model="variant.url"
              />
              <div
                class="input-errors"
                v-for="error of v$.variant.url.$errors"
                :key="error.$uid"
              >
                <small class="text-danger">{{ error.$message }}</small>
              </div>
            </b-form-group>

            <b-form-group :label="$t('forms.tags')">
              <v-select
                taggable
                multiple
                push-tags
                v-model="variant.tags"
                :options="variant.tags"
              />
            </b-form-group>
          </b-card>

          <b-card>
            <div v-if="variantParams && variantParams.length">
              <h5 class="mb-2">{{ $t("product.variantParams") }}</h5>
              <b-form-group
                v-for="(varParam, index) of variantParams"
                :key="index"
                :label="varParam.label"
              >
                <v-select
                  v-model="variant.customVariantParameters[varParam.id]"
                  :options="varParam.items"
                />
              </b-form-group>
            </div>

            <div v-if="infoParams && infoParams.length">
              <h5 class="mb-2 mt-4">{{ $t("product.infoParams") }}</h5>
              <b-form-group
                v-for="(infoParam, index) of infoParams"
                :key="index"
                :label="infoParam.label"
              >
                <v-select
                  v-model="variant.customInfoParameters[infoParam.id]"
                  :options="infoParam.items"
                />
              </b-form-group>
            </div>

            <div v-if="availableConfigurations">
              <h5 class="mb-2 mt-4">{{ $t("variant.configurations") }}</h5>
              <b-form-group>
                <v-select
                  multiple
                  v-model="variant.productConfigurations"
                  :options="availableConfigurations"
                />
              </b-form-group>
            </div>
          </b-card>
        </b-col>

        <b-col cols="12" lg="4">
          <b-card>
            <h5 class="mb-2">{{ $t("variant.pricesAndAvailability") }}</h5>

            <b-form-group :label="$t('variant.published')">
              <b-form-checkbox v-model="variant.onOff" switch></b-form-checkbox>
            </b-form-group>

            <b-form-group
              :class="{ error: v$.variant.purchasePrice.$errors.length }"
              :label="$t('variant.buyPrice')"
            >
              <b-form-input
                type="number"
                min="0"
                step="0.001"
                v-model="variant.purchasePrice"
              />
              <div
                class="input-errors"
                v-for="error of v$.variant.purchasePrice.$errors"
                :key="error.$uid"
              >
                <small class="text-danger">{{ error.$message }}</small>
              </div>
            </b-form-group>

            <b-form-group
              :class="{ error: v$.variant.price.$errors.length }"
              :label="$t('variant.sellPrice')"
            >
              <b-form-input
                type="number"
                min="0"
                step="0.001"
                v-model="variant.price"
              />
              <div
                class="input-errors"
                v-for="error of v$.variant.price.$errors"
                :key="error.$uid"
              >
                <small class="text-danger">{{ error.$message }}</small>
              </div>
            </b-form-group>

            <b-form-group
              :class="{ error: v$.variant.originalPrice.$errors.length }"
              :label="$t('variant.originalPrice')"
            >
              <b-form-input
                type="number"
                min="0"
                step="0.001"
                v-model="variant.originalPrice"
              />
              <div
                class="input-errors"
                v-for="error of v$.variant.originalPrice.$errors"
                :key="error.$uid"
              >
                <small class="text-danger">{{ error.$message }}</small>
              </div>
            </b-form-group>

            <b-form-group :label="$t('partner.singular')">
              <v-select v-model="selectedPartner" :options="partners" />
            </b-form-group>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </b-form>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import ListUtils from "@/mixins/ListUtils";
import ResourceUtils from "@/mixins/ResourceUtils";
import vSelect from "vue-select";

export default {
  components: {
    vSelect,
  },
  props: {
    productObj: { type: Object, default() {} },
    variantIri: { type: String, default: null },
  },
  setup: () => ({ v$: useVuelidate() }),
  validations() {
    return {
      variant: {
        name: { required: this.$translateError("required") },
        url: { required: this.$translateError("required") },
        purchasePrice: {
          required: this.$translateError("required"),
          decimal: this.$translateError("decimal"),
        },
        originalPrice: {
          required: this.$translateError("required"),
          decimal: this.$translateError("decimal"),
        },
        price: {
          required: this.$translateError("required"),
          decimal: this.$translateError("decimal"),
        },
      },
    };
  },
  data() {
    return {
      product: JSON.parse(JSON.stringify(this.productObj)),
      variant: undefined,
      productVariants: [],
      availableParams: [],
      variantParams: [],
      infoParams: [],
      availableVariantParams: [],
      availableInfoParams: [],
      availableConfigurations: [],
      partners: [],
      selectedPartner: null,
    };
  },
  mixins: [ListUtils, ResourceUtils],
  watch: {
    productObj: {
      deep: true,
      immediate: true,
      handler(val) {
        this.product = JSON.parse(JSON.stringify(val));
      },
    },
    variantParams() {
      this.productVariants.forEach((productVariant) => {
        this.variantParams.forEach((param) => {
          if (!productVariant.customVariantParameters[param.id]) {
            productVariant.customVariantParameters[param.id] = null;
          }
        });
      });
    },
    infoParams() {
      this.productVariants.forEach((productVariant) => {
        this.infoParams.forEach((param) => {
          if (!productVariant.customInfoParameters[param.id]) {
            productVariant.customInfoParameters[param.id] = null;
          }
        });
      });
    },
    variantIri: {
      deep: true,
      immediate: true,
      async handler() {
        if (this.variantIri) {
          this.variant = this.productVariants.find(
            (variant) => variant["@id"] === this.variantIri
          );

          const variantResponse = await this.$ProductVariants.getResourceByUrl({
            url: this.variantIri,
          });
          if (variantResponse) {
            this.variant = variantResponse.data;

            if (this.variant.partner) {
              this.variant.partner = this.partners.find(
                (partner) => partner.id === this.variant.partner
              );
            }

            this.selectedPartner = this.variant.partner;

            this.variant.customVariantParameters = [];
            this.variant.customInfoParameters = [];

            if (this.variant.productConfigurations) {
              const configurations = [];
              this.variant.productConfigurations.forEach((conf) => {
                configurations.push({ id: conf["@id"], label: conf.name });
              });
              this.variant.productConfigurations = configurations;
            }

            // presunute z created aby sa nezdielali parametre medzi variantami
            this.variantParams = [];
            this.infoParams = [];
            if (this.variant.customParameters) {
              this.variant.customParameters.forEach((param) => {
                const existing = this.availableParams.find(
                  (p) => p.id === param.customParameter["@id"]
                );
                if (existing) {
                  if (existing.type === "VARIANT") {
                    if (this.variantParams.indexOf(existing) === -1) {
                      this.variantParams.push(existing);
                    }
                    if (!this.variant.customVariantParameters[existing.id]) {
                      this.variant.customVariantParameters[existing.id] = null;
                    }
                    const existingItem = existing.items.find(
                      (i) => i.id === param.value
                    );
                    if (existingItem) {
                      this.variant.customVariantParameters[existing.id] =
                        existingItem;
                    }
                  } else {
                    if (this.infoParams.indexOf(existing) === -1) {
                      this.infoParams.push(existing);
                    }
                    if (!this.variant.customInfoParameters[existing.id]) {
                      this.variant.customInfoParameters[existing.id] = null;
                    }
                    const existingItem = existing.items.find(
                      (i) => i.id === param.value
                    );
                    if (existingItem) {
                      this.variant.customInfoParameters[existing.id] =
                        existingItem;
                    }
                  }
                }
              });
            }
          }
        } else {
          this.emptyVariant();
        }
      },
    },
    selectedPartner() {
      this.variant.partner = this.selectedPartner;
    },
  },
  async created() {
    this.availableConfigurations = await this.getConfigurationsList();
    this.availableParams = await this.getParametersList();
    await this.getUserList({ params: { role: "ROLE_PARTNER" } }, this.partners);

    for (const param of this.availableParams) {
      if (param.type === "VARIANT") {
        this.availableVariantParams.push(param);
      }
      if (param.type === "INFO") {
        this.availableInfoParams.push(param);
      }
    }

    if (!this.variantIri) {
      this.emptyVariant();
    }
  },
  methods: {
    emptyVariant() {
      this.variant = {
        name: "",
        url: "",
        price: "",
        originalPrice: "",
        purchasePrice: "",
        partner: null,
        onOff: false,
        product: this.product["@id"],
        customVariantParameters: [],
        customInfoParameters: [],
        productConfigurations: [],
      };
      this.selectedPartner = null;
      this.variantParams = [];
      this.infoParams = [];
    },
    presetSlug(obj, sourceKey, destKey) {
      if (obj[destKey].length === 0) {
        obj[destKey] = this.$helper.slugifyURL(obj[sourceKey]);
      }
    },
    slugifyURL(obj, key) {
      if (obj[key].length !== 0) {
        obj[key] = this.$helper.slugifyURL(obj[key]);
      }
    },
    async onSubmit() {
      const isValid = await this.v$.$validate();
      if (isValid) {
        const variant = this.variant;
        const body = {
          name: variant.name,
          url: variant.url,
          product: variant.product,
          price: variant.price,
          originalPrice: variant.originalPrice,
          purchasePrice: variant.purchasePrice,
          onOff: variant.onOff,
          productConfigurations: variant.productConfigurations.map(
            (con) => con.id
          ),
          customParameters: [],
          tags: variant.tags,
        };
        if (variant.partner) {
          body.partner = variant.partner.id;
        } else {
          body.partner = null
        }

        for (const p in variant.customVariantParameters) {
          const v = this.variantParams.find((variant) => variant.id === p);
          if (v) {
            const param = variant.customVariantParameters[p];
            if (param) {
              body.customParameters.push({
                customParameter: param.parameter["@id"],
                value: param.id,
              });
            }
          }
        }

        for (const p in variant.customInfoParameters) {
          const v = this.infoParams.find((variant) => variant.id === p);
          if (v) {
            const param = variant.customInfoParameters[p];
            if (param) {
              body.customParameters.push({
                customParameter: param.parameter["@id"],
                value: param.id,
              });
            }
          }
        }
        if (variant["@id"]) {
          this.update(
            this.$ProductVariants,
            variant.id,
            body,
            this.$t("variant.updated"),
            null,
            this.success
          );
        } else {
          this.create(
            this.$ProductVariants,
            body,
            this.$t("variant.created"),
            null,
            this.succes
          );
        }
      }
    },
    success() {
      this.emptyVariant();
      this.$emit("return");
    },
  },
};
</script>

<style lang="scss">
@import "vue-select/src/scss/vue-select.scss";
</style>
